import MapboxDraw from '@mapbox/mapbox-gl-draw';
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';

const DragFreeDirectSelect = { ...MapboxDraw.modes.direct_select, dragFeature() {} };

export const setupDraw = () => {
  return new MapboxDraw({
    displayControlsDefault: false,

    /**
     * We've disabled snapping because:
     * - No drawing modes in Shared Views currently require snapping, drawing is only used for selection bounds.
     * - VETRO's prefetch cache of geometry causes issues when large geometry (and/or large numbers of features) are queried
     *   This needs to be limited if we eventually need snapping, but for now we do not need to worry about it.
     * 
     * Ex: Toggling layers on/off was querying for all features in the map extent (as long as there were fewer than 50k features)
     *  however many features in California's BIP map were very large, and this would overwhelm our queue with messages too big for Rabbit to handle.
     */
    snapLayerFilter: () => false,
    fetchSourceGeometries: () => [],
    fetchSourceGeometry: () => null,
    fetchSnapGeometry: () => null,
    fetchSnapGeometries: () => [],
    fetchMapExtentGeometry: () => undefined,
    resetSnappingGeomCache: () => undefined,
    getClosestPoint: () => undefined,

    modes: {
      ...MapboxDraw.modes,
      static: StaticMode,
      direct_select: DragFreeDirectSelect,
    },
    keybindings: true,
    clickBuffer: 5,
    defaultMode: 'static',
  });
};
